import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w03MM02Blockfloete = () => (
  <Layout>
    <SEO title="w03MM02Blockfloete" />

    <h1 id="ueberschrift">Musik machen</h1>
    <h2>Blockflöte für Anfänger &emsp;  ©&nbsp;1989</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w03MM02Blockfloete&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem" style={{textDecoration: "underline"}}>
        Abendständchen
      </p>

  <div className="container">
    <div className="row">
      <div  style={{ width: `21em`, margin: "auto"}}>
      <p className="poem">
        Hör, es klang die Flöte wieder,<br />
        und die kühlen Brunnen rauschen!<br />
        Golden weh’n die Töne nieder,<br />
        stille, stille, laß uns lauschen!
      </p>
      </div>
      <div  style={{ width: `23em`, margin: "auto"}}>
      <p className="poem">
        Holdes Bitten, mild Verlangen,<br />
        wie es süß zum Herzen spricht!<br />
        Durch die Nacht, die dich umfangen,<br />
        blickt zu mir der Töne Licht!
      </p>
      </div>
    </div>
  </div>
      Clemens Brentano (1778 - 1842), deutscher Lyriker und Erzähler

    <p>---</p>

      <p>
        ... und eine Flöte lässt sich ganz einfach einpacken und mitnehmen. Selbst am Lagerfeuer
        klingt sie schön und ist dabei das perfekte Instrument, um sich vor dem Mitsingen zu drücken.
        Die Blockflöte ist überdies die bewährte Einstiegshilfe ins Notenlesen und zu weiteren (Blas-)Instrumenten. 
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitz- oder Stehplatz pro Teilnehmer + mind. 1 Sitz- oder Stehplatz extra<br/>
        Materialbedarf: + Blockflöte +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und etwas Freiraum
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w03MM02Blockfloete
